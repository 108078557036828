import React from 'react';
import styled from 'styled-components';
import KSidebar from './KSidebar';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 8em;
  margin-right: 6em;
  // grid-template-columns: repeat(12, 1fr);
  // grid-auto-rows: minmax(25px, auto);
`;
export const KButton = (props) => (


  <div>
 <KSidebar></KSidebar>
  
  <GridWrapper>
   <h1>Kotlin Button</h1>
  </GridWrapper>
  </div>
)