import React from "react"
import JavaSidenav from './JavaSidenav'
import styled from 'styled-components';


const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 10em;
  margin-right: 6em;
//   grid-template-columns: repeat(12, 1fr);
//   grid-auto-rows: minmax(25px, auto);
`;



export const EditText = () => {
    return(
        <div>
            
         
            <JavaSidenav></JavaSidenav>


<GridWrapper>
    <div>
    <h1>Edit Text</h1>
    

    
    </div>

</GridWrapper>
          

            
            

            

           
            
            
            
            
            
            
        </div>
    )
    }
   

export default EditText