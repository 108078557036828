import React from 'react';
import { Nav, Navbar, Form, FormControl } from 'react-bootstrap';
import styled from 'styled-components';




const Styles = styled.div`

  .navbar { background-color: #008000; 
    position: fixed;
  overflow: hidden;
  z-index: 1;
  width: 100%; 
  margin: auto;
  top: 0;}
  a, .navbar-nav, .navbar-light .nav-link {
    color: #000000; 
    &:hover { color: white; }
  }
  .navbar-brand {
    font-size: 1.4em;
    color: #17202A;
    &:hover { color: white; }
  }
  .form-center {
    position: absolute !important;
    left: 25%;
    right: 25%;
  }
  .navbar.collapse{
    font-size: 1.4em;
    text-align: right;
    
    color: #17202A;
    &:hover { color: white; }
  }
`;

export const NavigationBar = () => (
  <Styles>
    <Navbar expand="lg">
      <Navbar.Brand href="/">YesAndroid</Navbar.Brand>
      {/* <Navbar.Brand href="/">Java</Navbar.Brand>
      <Navbar.Brand href="/components/Kotlin/KTextView">Kotlin</Navbar.Brand> */}
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      {/* <Form className="form-center">
        <FormControl type="text" placeholder="Search" className="" />
      </Form> */}
      {/* <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item> 
          <Nav.Item><Nav.Link href="/about">About</Nav.Link></Nav.Item>
        </Nav>
      </Navbar.Collapse> */}
    </Navbar>
  </Styles>
)