import React from 'react';
import ReactDOM from 'react-dom';

import logo from './logo.svg';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch , browserHistory} from "react-router-dom";
import { NavigationBar } from './components/NavigationBar';

import { About } from './About';
import {JavaSidenav} from './components/Java/JavaSidenav';
import { NoMatch } from './NoMatch';
import {TextView} from './components/Java/TextView';
import  { EditText} from './components/Java/EditText';
import {Button} from './components/Java/Button';

import {KTextView} from './components/Kotlin/KTextView';
import {KEditText} from './components/Kotlin/Edittext';
import {KButton} from './components/Kotlin/Button';
import { Home } from './Home';

// import Sidebar from './components/Sidebar';

function App() {
  return (
    <React.Fragment>

      <br></br>
      <br></br>
      <Router >
        <NavigationBar />
        <Home></Home>
        {/* <br>
        </br> */}
        <br></br>

        {/* <Sidebar /> */}

        {/* <Switch>
          <Route exact path="/" component={TextView} />
          <Route path="/about" component={About} />
                   
          <Route path='/components/java/textview' component={TextView}/>
          <Route path='/components/java/edittext' component={EditText}/>
          <Route path='/components/java/button' component={Button}/>
          <Route path='/components/Kotlin/KTextView' component={KTextView}/>
          <Route path='/components/kotlin/edittext' component={KEditText}/>
          <Route path='/components/kotlin/button' component={KButton}/>
                   
          <Route component={NoMatch} />
        </Switch> */}
      </Router>
    </React.Fragment>
  );
}

export default App;
