import React from 'react';
import styled from 'styled-components';
import JavaSidenav from './components/Java/JavaSidenav';
const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  //margin-left: 8em;
  margin-right: 6em;
  // grid-template-columns: repeat(12, 1fr);
  // grid-auto-rows: minmax(25px, auto);
`;

const TestWrapper = styled.div`
display:flex;
justify-content: space-between;
position: absolute;
bottom: 8px;
right: 16px;
font-size: 18px;

`;

export const Home = (props) => (


  <div>
    
  
  <GridWrapper>
    <p>This is a paragraph and I am writing on the home page</p>
    <p>This is another paragraph, hi hey hello whatsup yo  home</p>

    <TestWrapper>

      
            <div>


            <p>Tel: +91 8130461500</p>
            <a href="https://play.google.com/store/apps/developer?id=YesAndroid">
                        <img src="google_play.webp" alt="Google Play" width="50" height="50"></img>
                        </a>
                        &nbsp;
                        &nbsp;&nbsp;
         
            <a href="https://www.linkedin.com/in/purushottam-kumar-63b868168/">
                        <img src="linkedin.png" alt="Linkedin" width="50" height="50"></img>
                        </a>
                
        
                       
         

            </div>
        </TestWrapper>
  </GridWrapper>

<div>
<footer>
  <p>Author: Hege Refsnes</p>
  <p><a href="mailto:hege@example.com">hege@example.com</a></p>
</footer>
</div>

 

  </div>
)