import React from "react";
import JavaSidenav from './JavaSidenav';
import styled from 'styled-components';

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  margin-top: 1em;
  margin-left: 10em;
  margin-right: 6em;

`;

const TestWrapper = styled.div`
display:flex;
justify-content: space-between;
position: absolute;
bottom: 8px;
right: 16px;
font-size: 18px;

`;

export const TextView = () => {
    return(
        <div> 
        <JavaSidenav></JavaSidenav>
        <GridWrapper>
            <div>
            <h1> Text View</h1>


          
          

            </div>

        </GridWrapper>  

        <TestWrapper>
            <div>


           

            <a href="https://play.google.com/store/apps/developer?id=YesAndroid">
                        <img src="google_play.webp" alt="Google Play" width="50" height="50"></img>
                        </a>
                        &nbsp;
                        &nbsp;&nbsp;
         
            <a href="https://www.linkedin.com/in/purushottam-kumar-63b868168/">
                        <img src="linkedin.png" alt="Linkedin" width="50" height="50"></img>
                        </a>
                
          

                        <p>Tel: +91 8130461500</p>
         

          


         
            </div>
        </TestWrapper>

        
        </div>
        )
    }

export default TextView